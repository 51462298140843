import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import PageBody from '../components/PageBody'

const Post = styled.li`
  margin: 0 0 3em 0;
  width: 100%;
  transition: background 0.2s;

  // for Link component
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    flex-flow: column nowrap;
  }
  display: flex;
  flex-flow: row nowrap;

  a {
    // &:hover {
    //   background: ${props => props.theme.colors.tertiary};
    // }
    color: ${props => props.theme.colors.text};
    text-decoration: none;
  }
`

const StyledImg = styled(StaticImage)`
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
`

const Title = styled.h2`
  line-height: 1.2;
  font-size: 1.8em;
  font-weight: 400;
  font-family: ${props => props.theme.fonts.title};
  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    flex: auto;
    margin: 0 0 1rem 0;
    font-size: 1.6em;
  }
  margin: 0 3vw 1rem 0;
  flex: 0 0 21rem;
`

const Date = styled.p`
  margin: 0.5rem 0;
  font-size: 0.6em;
  font-weight: 400;
  color: gray;
`

const Excerpt = styled.div`
  // margin: 0 1rem 1rem 1rem;
  flex: auto;
`

const Card = ({ slug, heroImage, title, publishDate, body, ...props }) => {
  return (
    <>
      {body && (
        <Post featured={props.featured}>
          <Title>
            <Link to={`${props.basePath}/${slug}/`}>
              {title}
              <Date>{publishDate}</Date>
            </Link>
          </Title>
          {/* <Link to={`${props.basePath}/${slug}/`}>
            <Excerpt>
              <PageBody body={body} />
            </Excerpt>
          </Link> */}

          <Link to={`${props.basePath}/${slug}/`}>
            <Excerpt
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.excerpt,
              }}
            />
          </Link>
        </Post>
      )}
    </>
  )
}

export default Card
